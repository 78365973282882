<template>
  <standard-page title="Attestation Requests" :definition="$DEFINITIONS.attestationRequests">
    <template v-slot:breadcrumb>
      <span>
        <router-link :to="{ name: 'identities-home'}">Identifier</router-link>
      </span>
      <span>Attestation Requests</span>
    </template>
    <template v-slot:content>
      <data-table :api="api" :columns="columns" :row-click="view" list-key="dids"></data-table>
    </template>
  </standard-page>
</template>

<script>

export default {
  name: "RequestedAttestations",
  data() {
    return {
      api: `${this.$identity.defaults.baseURL}/identity/dids/authorizations?permission=Issuer`,
      columns: [
        { title: 'Catalog', data: 'catalog_name' },
        { type: 'did', data: 'did.did' },
        { title: 'Name', data: 'did', render: data => data.short_name ? data.short_name : '' },
        { title: 'Claims', data: 'claims_count', orderable: false },
        { title: 'Attestations', data: 'attestations_count', orderable: false },
        { title: 'Awaiting Attestation', data: 'awaiting_attestation', orderable: false },
      ],

    };
  },
  methods: {
    view(entity) {
      this.$router.push({
        name: 'view-attestation-requests', params: {
          did: entity.did.did,
          short_nameProp: entity.did.short_name
        }
      });
    },
  }
}
</script>

<style scoped>
.masked {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>